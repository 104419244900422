.serv-tbl__item.open .serv-tbl__toggle {
    background-color: var(--gray-clr);
}

.serv-tbl__item.open .serv-tbl__toggle::after {
    display: none;
}

.hidden {
    display: none !important;
}

.dp__input {
    background-color: transparent !important;
    border: none !important;
}

.date-picker.form-field--m {
    padding: 0 !important;
}

.comment-answer__date {
    font-weight: 400;
}

.pu-wrap-request-form {
    z-index: 65;
}

.more-item.open {
    --marker-transform: rotate(180deg);
}

.more-item_clickable {
    cursor: pointer;
}

.rel-task__main {
    text-decoration: none;
}

.dp__cell_highlight {
    color: #ff370b !important;
    background-color: transparent !important;
}

.p-editor-container {
    position: relative !important;
    max-height: 300px !important;
    width: 90%;
}

.ql-toolbar {
    padding: 0 !important;
    font-family: inherit !important;
    border: none !important;
    position: absolute !important;
    top: -45px;
    left: 0;
    background-color: #f4f6f8 !important;
    border-radius: 5px !important;
    z-index: 10;
}

.ql-toolbar {
    background-color: #22272f !important;
}

.ql-container {
    border: none !important;
    font-family: inherit !important;
    font-size: inherit !important;
}

.ql-editor {
    overflow-y: scroll;
    padding: 0 !important;
    line-height: 1.5 !important;
    max-height: 280px !important;
}

.ql-editor::-webkit-scrollbar {
    display: none;
}

.ql-editor.ql-blank::before {
    color: #9eaab3 !important;
    left: 0 !important;
    font-style: normal !important;
}

.ql-buttons {
    padding: 8px;
}

.ql-formats {
    margin: 0 !important;
}

.ql-stroke {
    stroke: #ffffff !important;
}

.ql-active {
    stroke: #0054c6 !important;
}

.text p:not(:last-child) {
    margin-bottom: 0.5em;
}

.task-comment__text li {
    padding-left: 1.5rem;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
    list-style-type: none;
}

.task-comment__text li::before {
    margin-left: -1.5rem;
    margin-right: 0.3rem;
    text-align: right;
    content: counter(list-0, decimal) '. ';
    display: inline-block;
    white-space: nowrap;
    width: 1.2rem;
}

li.ql-indent-1 {
    padding-left: 3rem !important;
}

.task-comment__text li.ql-indent-1 {
    padding-left: 3rem;
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-1;
}

.task-comment__text li.ql-indent-1::before {
    content: counter(list-1, lower-alpha) '. ';
}

.content__top_link {
    justify-content: space-between;
}

.content__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination__list
{
    display: flex;

    margin: 0;
    padding: 0;

    list-style: none;

    align-items: center;
    justify-content: center;
    gap: var(--gap-2xs);
}

.pagination__link
{
    font-weight: 500;
    color: var(--text-clr);

    display: flex;

    width: var(--icon-size-lg);
    height: var(--icon-size-lg);

    border-radius: var(--radius-md);

    align-items: center;
    justify-content: center;

    text-decoration: none;
}

.pagination__link.is-inactive
{
    pointer-events: none;

    color: var(--gray-clr);
}

.pagination__link:hover
{
    background-color: var(--gray-light-clr);
}

.pagination__link.is-active
{
    pointer-events: none;

    color: var(--main-clr);
    background-color: var(--bg-s-link-active-clr);
}